import React, { useState} from 'react';

import { Box, Button, TextField, Snackbar, Typography } from '@mui/material';

import MuiAlert from '@mui/material/Alert';

import { Select } from 'antd';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

import SendIcon from '@mui/icons-material/Send';

import { Formik } from 'formik';

import * as Yup from 'yup';



const SrkSchema = Yup.object().shape({
  course: Yup.string()
    .required('Select Course Name!'),
  name: Yup.string()
    .required('Enter Name!'),
  mobile: Yup.string()
  .required("required"),
  message: Yup.string()
    .required('Enter Message!')
    .min(5, "too short")
    .max(200, "too long"),
  email: Yup.string().email('Invalid email format').required('Required'),
    
  
});



export default function (props) {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [alertstatus, setAlertstatus] = useState(''); 

  const [courseitems, setCourseitems] = React.useState(props.courses); 

  const snackbarcall = (message1, status1) =>
  {
    setMessage(message1);
    setAlertstatus(status1);
    setOpen(true);

  }

 


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
   
      return (
          <>
           <Formik
        // initialValues={{ rdate: domaindata.rdate, domain: domaindata.domain, seller:domaindata.seller, cost: domaindata.cost, expiry: domaindata.expiry, status: domaindata.status }}
        enableReinitialize
        initialValues={{ name: '', mobile: '', email:'', message: '', course: '' }}
        validationSchema={SrkSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const axios = require('axios').default;
          axios.post('/contactaction', {
            name: values.name,
            mobile: values.mobile,
            email: values.email,
            message: values.message, 
            service: values.course,

      })
      .then(function (response) {
          if(response.data.result === true)
          { 
            
            snackbarcall('Submitted Successfully', 'success')           
              resetForm({values: ''});
             
            
          }
          else
          {                                              
            setSubmitting(false);
            snackbarcall('Error!', 'warning')  ;                  
          }
         
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });



        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>            
    
    <Box my={2}>
    <Typography variant="body1" gutterBottom>
      Select Course
    </Typography>
    <Select
          style={{ width: '100%' }}
          value={values.course}
          onChange={(value) => {setFieldValue('course', value)}}
          status = {errors.course && touched.course ? 'error':null}
          showSearch
          size="large"
          dropdownRender={(menu) => (
            <>
              {menu}
         
            </>
          )}
          options={courseitems.map((item) => ({
            label: item.courseName,
            value: item.courseName,
          }))}
        />
     </Box>

<TextField
            id="name"
            name="name"
            label="Name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            variant="outlined"
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            fullWidth margin="normal"
            />
<TextField
            id="email"
            name="email"
            label="Email"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            variant="outlined"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            fullWidth margin="normal"
            />
<TextField
            id="mobile"
            name="mobile"
            label="Mobile"
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobile}
            variant="outlined"
            error={touched.mobile && Boolean(errors.mobile)}
            helperText={touched.mobile && errors.mobile}
            fullWidth margin="normal"
            />
            <TextField
            id="message"
            name="message"
            label="Message"
            type="text"
            multiline
            rows={5}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
            variant="outlined"
            error={touched.message && Boolean(errors.message)}
            helperText={touched.message && errors.message}
            fullWidth margin="normal"
            />

  

            <Box pt={2}>
            <Button variant="contained" type="submit" disabled={isSubmitting} endIcon={<SendIcon/>}>Submit</Button>
            </Box>
          </form>
        )}
      </Formik>
        
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleClose} severity={alertstatus} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
       
          </>

        );
    }
