import React, { useState} from 'react';

import { Box, Button, TextField, Snackbar } from '@mui/material';

import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

import SendIcon from '@mui/icons-material/Send';

import { Formik } from 'formik';

import * as Yup from 'yup';



const SrkSchema = Yup.object().shape({
    studentName: Yup.string()
      .required('Enter Student Name!'),
      finNumber: Yup.string()
      .required('Enter Fin/ NRIC Number!'),
      courseName: Yup.string()
      .required('Enter Course Name!'),
      completionYear: Yup.string()
      .required('Enter Completion Year!'),
      requesterName: Yup.string()
      .required('Enter Requester Name!'),
    message: Yup.string()
      .required('Enter Message!')
      .min(5, "too short")
      .max(200, "too long"),
    email: Yup.string().email('Invalid email format').required('Required'),
  });



export default function () {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [alertstatus, setAlertstatus] = useState(''); 

  const snackbarcall = (message1, status1) =>
  {
    setMessage(message1);
    setAlertstatus(status1);
    setOpen(true);

  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
   
      return (
          <>
           <Formik
        // initialValues={{ rdate: domaindata.rdate, domain: domaindata.domain, seller:domaindata.seller, cost: domaindata.cost, expiry: domaindata.expiry, status: domaindata.status }}
        enableReinitialize
        initialValues={{ studentName: '', finNumber: '', courseName:'', completionYear:'', requesterName:'', email:'', message: '' }}
        validationSchema={SrkSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const axios = require('axios').default;
          axios.post('/verificationaction', {
            studentName: values.studentName,
            finNumber: values.finNumber,
            courseName: values.courseName,
            completionYear: values.completionYear,
            requesterName: values.requesterName,
            email: values.email,
            message: values.message,

      })
      .then(function (response) {
          if(response.data.result === true)
          { 
            
            snackbarcall('Submitted Successfully', 'success')           
              resetForm({values: ''});
             
            
          }
          else
          {                                              
            setSubmitting(false);
            snackbarcall('Error!', 'warning')  ;                  
          }
         
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });



        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>            
    
    <TextField
            id="studentName"
            name="studentName"
            label="Student Name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.studentName}
            variant="outlined"
            error={touched.studentName && Boolean(errors.studentName)}
            helperText={touched.studentName && errors.studentName}
            fullWidth margin="normal"
            />
<TextField
            id="finNumber"
            name="finNumber"
            label="Fin / NRIC Number"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.finNumber}
            variant="outlined"
            error={touched.finNumber && Boolean(errors.finNumber)}
            helperText={touched.finNumber && errors.finNumber}
            fullWidth margin="normal"
            />
<TextField
            id="courseName"
            name="courseName"
            label="Course Name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.courseName}
            variant="outlined"
            error={touched.courseName && Boolean(errors.courseName)}
            helperText={touched.courseName && errors.courseName}
            fullWidth margin="normal"
            />
<TextField
            id="completionYear"
            name="completionYear"
            label="Completion Year"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.completionYear}
            variant="outlined"
            error={touched.completionYear && Boolean(errors.completionYear)}
            helperText={touched.completionYear && errors.completionYear}
            fullWidth margin="normal"
            />
<TextField
            id="requesterName"
            name="requesterName"
            label="Requester Name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.requesterName}
            variant="outlined"
            error={touched.requesterName && Boolean(errors.requesterName)}
            helperText={touched.requesterName && errors.requesterName}
            fullWidth margin="normal"
            />


<TextField
            id="email"
            name="email"
            label="Email"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            variant="outlined"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            fullWidth margin="normal"
            />

            <TextField
            id="message"
            name="message"
            label="Message"
            type="text"
            multiline
            rows={5}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
            variant="outlined"
            error={touched.message && Boolean(errors.message)}
            helperText={touched.message && errors.message}
            fullWidth margin="normal"
            />

  

            <Box pt={2}>
            <Button variant="contained" type="submit" disabled={isSubmitting} endIcon={<SendIcon/>}>Submit</Button>
            </Box>
          </form>
        )}
      </Formik>
        
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleClose} severity={alertstatus} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
       
          </>

        );
    }

